// TextUserAvatar.js
import { connect } from 'react-redux';

import fetchUser from '../action/fetchUser.js';

import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';

import TextUserAvatar from '../component/TextUserAvatar.jsx';

const mapStateToProps = (state, { userId }) => {
  const meId = getMeData(state, 'id');
  const avatarColor = userId
    ? userId === meId
      ? getMeData(state, 'avatarColor')
      : getUserData(state, userId, 'avatarColor')
    : null;
  const username = userId
    ? userId === meId
      ? getMeData(state, 'username')
      : getUserData(state, userId, 'username')
    : null;
  return {
    meId,
    username,
    avatarColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: ({ userId }) => dispatch(fetchUser({ id: userId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextUserAvatar);
