// MeAvatar.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import StatefulImage from '../component/StatefulImage.jsx';

import TextUserAvatar from '../container/TextUserAvatar.js';
import DecryptionWrapper from '../container/DecryptionWrapper.js';

import { TranslationNamespace } from '../resource/translationNamespace.js';

export class MeAvatar extends React.PureComponent {
  render() {
    const { meId, size, avatarImageSource, t, children } = this.props;
    return (
      <StyledMeAvatar size={size}>
        <StatefulImage>
          <DefaultAvatarWrapper data-key="loading">
            <TextUserAvatar userId={meId} avatarSize={size} />
          </DefaultAvatarWrapper>
          <DefaultAvatarWrapper data-key="error">
            <TextUserAvatar userId={meId} avatarSize={size} />
          </DefaultAvatarWrapper>
          <DecryptionWrapper resourceUrl={avatarImageSource} data-key="target">
            <AvatarImage
              src={avatarImageSource}
              alt={t('avatar', {
                ns: TranslationNamespace.GENERAL,
              })}
            />
          </DecryptionWrapper>
        </StatefulImage>
        {children}
      </StyledMeAvatar>
    );
  }
}

MeAvatar.propTypes = {
  t: PropTypes.func.isRequired,
  avatarImageSource: PropTypes.string.isRequired,
  size: PropTypes.number,
  meId: PropTypes.string,
  children: PropTypes.node,
};

MeAvatar.defaultProps = {
  size: 84,
  meId: null,
};

const StyledMeAvatar = styled.div`
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  overflow: hidden;
  position: relative;
`;

const DefaultAvatarWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default withTranslation()(MeAvatar);
