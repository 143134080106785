// MeAvatar.js
'use strict';
import { connect } from 'react-redux';

import getMeData from '../selector/getMeData.js';
import MeAvatar from '../component/MeAvatar.jsx';

import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';

const mapStateToProps = state => {
  const meId = getMeData(state, 'id');
  return {
    meId,
    username: getMeData(state, 'username'),
    avatarColor: getMeData(state, 'avatarColor'),
    avatarImageSource:
      getMeData(state, 'picture') ||
      getUserPicture({
        userId: meId,
        meId,
        size: 512,
        format: MediaAssetFormat.JPG,
      }).href,
  };
};

export default connect(mapStateToProps)(MeAvatar);
