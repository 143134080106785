// getNavigationIcon.js
'use strict';

// home
import ActiveHomeIcon from '../../img/ic_tab_home_active.svg';
import HomeIcon from '../../img/ic_tab_home.svg';

// video
import ActiveVideoIcon from '../../img/ic_video_tealblue.svg';
import VideoIcon from '../../img/ic_video_white.svg';

// shorts
import ActiveShortIcon from '../../img/ic_short_tealblue.svg';
import ShortIcon from '../../img/ic_short_white.svg';

// feed
import ActiveInboxIcon from '../../img/ic_tab_newsfeeds_active.svg';
import InboxIcon from '../../img/ic_tab_newsfeeds.svg';

// unread chat
import ActiveUnreadChatIcon from '../../img/ic_tab_chat_new_active.svg';
import UnreadChatIcon from '../../img/ic_tab_chat_new.svg';

// chat
import ActiveChatIcon from '../../img/ic_tab_chat_active.svg';
import ChatIcon from '../../img/ic_tab_chat.svg';

// unauthorized me
import ActiveHamburgerIcon from '../../img/ic_hamburger_active.svg';
import HamburgerIcon from '../../img/ic_hamburger.svg';

// authorized me
import UserAvatarHamburgerIcon from '../../img/ic_hamburger_grey.svg';
import ActiveUserAvatarHamburgerIcon from '../../img/ic_hamburger_white.svg';

// leaderboard
import ActiveLeaderboardIconSource from '../../img/ic_leaderboard_active.svg';
import LeaderboardIconSource from '../../img/ic_leaderboard.svg';

// create
import ActiveCreateIconSource from '../../img/ic_create_active.svg';
import CreateIconSource from '../../img/ic_create.svg';

// logo
import LogoResource from '../../img/login_swag_icon.svg';

// backpack
import ActiveBackpackIconSource from '../../img/ic_topbar_backpack_active.svg';
import BackpackIconSource from '../../img/ic_topbar_backpack.svg';

// notification
import ActiveNotificationIconSource from '../../img/ic_topbar_nofitication_active.svg';
import NotificationIconSource from '../../img/ic_topbar_nofitication.svg';

// unread notification
import ActiveNotificationIconUnreadSource from '../../img/ic_topbar_nofitication_reddot_active.svg';
import NotificationIconUnreadSource from '../../img/ic_topbar_nofitication_reddot.svg';

// search
import ActiveSearchIconSource from '../../img/ic_search_tealblue.svg';
import SearchIconSource from '../../img/ic_search_white.svg';

export const NavigationId = {
  BROWSE_FOLLOWING: 'browse-following',
  BROWSE_EXPLORE: 'browse-explore',
  BROWSE: 'browse',
  HOME: 'home',
  VIDEO: 'video',
  CATEGORY: 'category',
  SHORTS: 'shorts',
  FEED: 'feed',
  CHAT: 'chat',
  ME: 'me',
  LEADERBOARD: 'leaderboard',
  CREATE: 'create',
  LOGO: 'logo',
  BACKPACK: 'backpack',
  NOTIFICATION: 'notification',
  SEARCH: 'search',
};

/**
 * Get navigation icon.
 * @param {NavigationId} {navigationId} - navigation id.
 * @param {boolean} {isMatch} - is route matched.
 * @param {boolean} {hasUnread} - has unread items.
 * @param {boolean} {isAuthed} - is authorized.
 * @return {string} Return navigation icon.
 */
const getNavigationIcon = ({
  navigationId,
  isMatch,
  hasUnread,
  isAuthed,
} = {}) => {
  switch (navigationId) {
    case NavigationId.HOME: {
      return isMatch ? ActiveHomeIcon : HomeIcon;
    }
    case NavigationId.VIDEO: {
      return isMatch ? ActiveVideoIcon : VideoIcon;
    }
    case NavigationId.SHORTS: {
      return isMatch ? ActiveShortIcon : ShortIcon;
    }
    case NavigationId.FEED:
    case NavigationId.BROWSE: {
      return isMatch ? ActiveInboxIcon : InboxIcon;
    }
    case NavigationId.CHAT: {
      return hasUnread
        ? isMatch
          ? ActiveUnreadChatIcon
          : UnreadChatIcon
        : isMatch
          ? ActiveChatIcon
          : ChatIcon;
    }
    case NavigationId.ME: {
      return isAuthed
        ? isMatch
          ? ActiveUserAvatarHamburgerIcon
          : UserAvatarHamburgerIcon
        : isMatch
          ? ActiveHamburgerIcon
          : HamburgerIcon;
    }
    case NavigationId.LEADERBOARD: {
      return isMatch ? ActiveLeaderboardIconSource : LeaderboardIconSource;
    }
    case NavigationId.CREATE: {
      return isMatch ? ActiveCreateIconSource : CreateIconSource;
    }
    case NavigationId.LOGO: {
      return LogoResource;
    }
    case NavigationId.BACKPACK: {
      return isMatch ? ActiveBackpackIconSource : BackpackIconSource;
    }
    case NavigationId.NOTIFICATION: {
      return hasUnread
        ? isMatch
          ? ActiveNotificationIconUnreadSource
          : NotificationIconUnreadSource
        : isMatch
          ? ActiveNotificationIconSource
          : NotificationIconSource;
    }
    case NavigationId.SEARCH: {
      return isMatch ? ActiveSearchIconSource : SearchIconSource;
    }
    default: {
      return '';
    }
  }
};

export default getNavigationIcon;
