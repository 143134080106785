// fetchUser.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';

import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_USER,
} from '../ActionTypes.js';

/**
 * Fetch user
 * @kind action
 * @param {string} {id} - user id.
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
const fetchUser =
  ({ id, httpProxyHeaders = {} }) =>
  async (dispatch, getState) => {
    const selectPath = ['user', id];
    const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');
    const token = getMeData(getState(), 'token');
    if (!id || isFetching) {
      return { type: '' };
    }

    const stateBeforeFetching = getState();

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
        ...httpProxyHeaders,
      },
    };
    if (token) {
      fetchOptions.headers.Authorization = `Bearer ${token}`;
    }
    try {
      const url = getResourceUrl({ endpoint: `/users/${id}` });
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      // Since `SET_NETWORKING_FETCHING` will replace last etag value, we need to use state before `SET_NETWORKING_FETCHING` dispatch.
      // But seems server not return `etag` now.
      const lastEtag = getNetworkingData(
        stateBeforeFetching,
        selectPath,
        'etag'
      );
      const etag = response.headers.get('etag');
      if (etag && etag === lastEtag) {
        return dispatch({
          type: SET_NETWORKING_SUCCESS,
          payload: { selectPath, etag },
        });
      }

      const user = await response.json();
      await dispatch({
        type: MERGE_USER,
        payload: {
          user: {
            ...user,
            // keep track of the last update time for display name
            displayNameLastUpdated: +new Date(response.headers.get('date')),
          },
        },
      });

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath, etag },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchUser;
